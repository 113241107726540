@import '../assets/styles/variables.scss';
body{
    margin:0px;
}
.mainContainer{
    height:600vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: relative;
}

.menu{
    overflow: hidden;
    height:100vh;
    padding:0;
    margin:0;
    width: 100vw;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 3;
}

.home{
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: white;
    justify-content: flex-end;;
}

.services{
    height:100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.process{
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: white;
}

.work{
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.about{
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color:white;
}

.contact{
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color:white;
}

.clutchWeb{
    height:375px;
    margin-left: 10vw;
    margin-right: 10vw;
}

.clutchMobile{
    height:600px;
}

////NAVBAR/////////////////////////////////
.navbar{
    position: fixed;
    top:0;
    left:0;
    right:0;
    height:14vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    z-index:2;
}

.logo{
    height:10vh;
    margin-left: 20px;
    width: auto;
    cursor: pointer;
}

.pageName{
    align-self: center;
    font-family:$primary-font;
    font-weight: 700;
    font-size:1.4rem;
}

.left{
    display: flex;
    flex-direction: row;
}

.rightFull{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-right:10px;
}

.menuIcon{
    height:4vh;
    padding:10px;
    width: auto;
}

.sectionTitle{
    font-family:$primary-font;
    width: 7vw;
    font-weight: 300;
    font-size:1.4rem;
    align-self: center;
    text-align: center;
    font-size: 1.1rem;
    position: relative;
    text-decoration: none;
}

.sectionTitle:hover{
    cursor: pointer; 
}

.sectionTitle:hover::after {
    content:'';
    position:absolute;
    width: 100%;
    height: 0;    
    left:0;
    bottom:-5px; /*Change this to increase/decrease distance*/
    border-bottom: 4px solid;  
}

.sectionTitleSelected{
    @extend .sectionTitle;
    font-weight: 700;
}

.sectionTitleHome{
    @extend .sectionTitle;
}

.sectionTitleHome:hover::after{
    text-decoration-color:$blue;
    border-color:$blue!important;
}

.sectionTitleServices{
    @extend .sectionTitle;
    
}

.sectionTitleServices:hover::after{
    text-decoration-color:$yellow;
    border-color:$yellow!important;
}

.sectionTitleProcess{
    @extend .sectionTitle;
}

.sectionTitleProcess:hover::after{
    text-decoration-color:$red;
    border-color:$red;
}

.sectionTitleWork{
    @extend .sectionTitle;
}

.sectionTitleWork:hover::after{
    text-decoration-color:$purple;
    border-color:$purple;
}

.sectionTitleAbout{
    @extend .sectionTitle;
}

.sectionTitleAbout:hover::after{
    text-decoration-color:$green;
    border-color:$green;
}

.sectionTitleContact{
    @extend .sectionTitle;
}

.sectionTitleContact:hover::after{
    text-decoration-color:$orange;
    border-color:$orange;
}

.sectionTitleCondensed{
    cursor:pointer;
    font-family:$primary-font;
    width: 100vw!important;
    font-weight: 300;
    align-self: center;
    text-align: center;
    font-size: 2rem;
    position: relative;
    text-decoration: none;
    margin-bottom:6vh;
}

.sectionTitleCondensed:hover::after {
    content:'';
    position:absolute;
    width: 50%;
    margin-left:25%;
    height: 0;    
    left:0;
    bottom:-5px; /*Change this to increase/decrease distance*/
    border-bottom: 4px solid;  
}

.sectionTitleCondensedSelected{
    @extend .sectionTitleCondensed;
    font-weight: 700;
}

.sectionTitleSelected{
    @extend .sectionTitle;
    font-weight: 700;
}


.sectionTitleHomeSelected{
    @extend .sectionTitleSelected;
}

.sectionTitleServicesSelected{
    @extend .sectionTitleSelected;
}

.sectionTitleProcessSelected{
    @extend .sectionTitleSelected;
}

.sectionTitleWorkSelected{
    @extend .sectionTitleSelected;
}

.sectionTitleAboutSelected{
    @extend .sectionTitleSelected;
}

.sectionTitleContactSelected{
    @extend .sectionTitleSelected;
}


.sectionCondensedTitleHomeSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    color:white;
    border-color:$blue!important;
    text-align: center;
}

.sectionCondensedTitleProcessSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    color:white;
    border-color:$red!important;
    text-align: center;
}

.sectionCondensedTitleServicesSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    color:white;
    border-color:$yellow!important;
    text-align: center;
}


.sectionCondensedTitleWorkSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    color:white;
    border-color:$purple!important;
    text-align: center;
}

.sectionCondensedTitleAboutSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    color:white;
    border-color:$green!important;
    text-align: center;
}

.sectionCondensedTitleContactSelected{
    @extend .sectionTitleCondensedSelected;
    font-weight: 700;
    text-align: center;
    color:white;
    border-color:$orange!important;
}

.sectionCondensedTitleHome{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleHome:hover::after{
    text-decoration-color:$blue;
    border-color:$blue!important;
    text-align: center;
}

.sectionCondensedTitleServices{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleServices:hover::after{
    text-decoration-color:$yellow;
    border-color:$yellow!important;
    text-align: center;
}

.sectionCondensedTitleProcess{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleProcess:hover::after{
    text-decoration-color:$red;
    border-color:$red;
    text-align: center;
}

.sectionCondensedTitleWork{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleWork:hover::after{
    text-decoration-color:$purple;
    border-color:$purple;
    text-align: center;
}

.sectionCondensedTitleAbout{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleAbout:hover::after{
    text-decoration-color:$green;
    border-color:$green;
    text-align: center;
}

.sectionCondensedTitleContact{
    @extend .sectionTitleCondensed;
    color:white;
    text-align: center;
}

.sectionCondensedTitleContact:hover::after{
    text-decoration-color:$orange;
    border-color:$orange;
    text-align: center;
}



////SIDEBAR/////////////////////////////////

.sidebar{
    padding:10px;
    display: flex;
    justify-content: space-between;
    float:end;
    right:0;
    top:25vh;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    width: 3vw;
    height: 50vh;
    background-color: transparent;
}

.sidebarElement{
    height:1vw;
    width: 3vw;
}

.sidebarElement:hover{
    cursor: pointer;
}

.sidebarElementSelected{
    height:3vw;
    width: 3vw;
}

.sidebarHome{
    @extend .sidebarElement;
    background-color: $blue;
}

.sidebarHomeSelected{
    @extend .sidebarElementSelected;
    background-color: $blue;
}


.sidebarServices{
    @extend .sidebarElement;
    background-color: $yellow;
}

.sidebarServicesSelected{
    @extend .sidebarElementSelected;
    background-color: $yellow;
}

.sidebarProcess{
    @extend .sidebarElement;
    background-color: $red;
}

.sidebarProcessSelected{
    @extend .sidebarElementSelected;
    background-color: $red;
}

.sidebarWork{
    @extend .sidebarElement;
    background-color: $purple;
}

.sidebarWorkSelected{
    @extend .sidebarElementSelected;
    background-color: $purple;
}

.sidebarAbout{
    @extend .sidebarElement;
    background-color: $green;
}

.sidebarAboutSelected{
    @extend .sidebarElementSelected;
    background-color: $green;
}

.sidebarContact{
    @extend .sidebarElement;
    background-color: $orange;
}

.sidebarContactSelected{
    @extend .sidebarElementSelected;
    background-color: $orange;
}

.closeRow{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.close{
    cursor: pointer;
    padding:15px;
    height:4vh;
    width: 4vh;
}