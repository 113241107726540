@import '../assets/styles/variables.scss';

.column{
    padding-top:10vh;
    display:flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    justify-content: space-evenly;
    align-items: center;
}

.row{
    padding-top:10vh;
    display:flex;
    flex-direction: row;
    width:100vw;
    height:100vh;
    justify-content: space-evenly;
    align-items:center;
}

.halfHorizontal{
    height: 75vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-start;
}

.contentVertical{
    width: 85vw;
}

.titlePortrait{
    font-size: 8vw;
    font-weight: 700;
    font-family: $primary-font;
}

.title{
    font-size: 3.8vw;
    font-weight: 700;
    font-family: $primary-font;
}

.descriptionPortrait{
    max-width: 90vw;
    font-size: 3.5vw;
    font-weight: 300;
    font-family: $primary-font;
    align-self: center;
}

.description{
    max-width: 90vw;
    font-size: 1.4vw;
    font-weight: 200;
    font-family: $primary-font;
    padding-bottom:30px;
}

.descriptionTop{
    max-width: 90vw;
    font-size: 1.4vw;
    font-weight: 200;
    font-family: $primary-font;
    padding-bottom:30px;
    padding-top:10vh;
}

.formVertical{
    height: 45vh;
    width: 85vw;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.formHorizontal{
    height: 80vh;
    width: 50vw;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.formColumn{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
}

.formRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

textarea{
    resize: none;
}

form{
    width: 100%;
    height: 100%;
}

.formName{
    width: 22vw;
    height:auto;
    color:black;
    border:3px solid black;
    font-family: $primary-font;
    font-size: 1.3vw;
    padding:1vw;
     font-weight: 300;
}

.formNamePortrait{
    width: 75vw;
    height:auto;
    color:black;
    border:3px solid black;
    font-family: $primary-font;
    font-size: 4vw;
    padding:1vw;
    margin-bottom: 1vh;
    font-weight: 300;
}

.formNamePortrait::placeholder{
    color:black;
    font-weight: 300;
    font-family: $primary-font;
    font-size: 4vw;
}


.formName::placeholder{
    color:black;
    font-weight: 300;
    font-family: $primary-font;
    font-size: 1.3vw;
}

.formMessagePortrait::placeholder{
    color:black;
    font-weight: 300;
    font-family: $primary-font;
    font-size: 4vw;
}

.formMessage{
    margin-top:5vh;
    width: 47.5vw;
    height:auto;
    color:black;
    border:3px solid black;
    font-family: $primary-font;
    font-size: 1.3vw;
    font-weight: 300;
    padding:1vw;
}

.formMessagePortrait{
    width: 75vw;
    height:auto;
    color:black;
    border:3px solid black;
    font-family: $primary-font;
    font-size: 4vw;
    padding:1vw;
    margin-bottom: 1vh;
}

textarea:focus, input:focus{
    outline: none;
}

.error{
    height:auto;
    color:$red;
    font-family: $primary-font;
    font-size: 1.5vw;
    font-weight: 300;
    margin-top:5vh;
}

.success{
    height:auto;
    color:$green;
    font-family: $primary-font;
    font-size: 1.5vw;
    font-weight: 300;
    margin-top:5vh;
}

.errorPortrait{
    height:auto;
    color:$red;
    font-family: $primary-font;
    font-size: 4vw;
    font-weight: 300;
   // margin-top:5vh;
}

.successPortrait{
    height:auto;
    color:$green;
    font-family: $primary-font;
    font-size: 4vw;
    font-weight: 300;
  //  margin-top:5vh;
}





.formMessage::placeholder{
    color:black;
    font-weight: 300;
    font-family: $primary-font;
    font-size: 1.3vw;
}

.buttonHalf{
    float:right;
    cursor: pointer;
    height:50px;
    margin-top:5vh;
    width: 20vw;
    margin-bottom:5vh;
    color:black;
    font-weight: 500;
    font-family: $primary-font;
    font-size: 1.3vw;
    background-color: white;
    border:3px solid black;
}

.buttonHalf:hover{
    background-color: $orange;
}

.buttonFull{
    cursor: pointer;
    height:auto;
    width: 78vw;
    color:black;
    font-weight: 500;
    font-family: $primary-font;
    font-size: 5vw;
    background-color: white;
    border:3px solid black;
}

.buttonFull:hover{
    background-color: $orange;
}