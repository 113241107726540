//Font Awesome
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css");

/* COLORS */
$blue: #477EE4; 
$yellow: #F3CA3E;
$green: #519E55;
$purple:#8A44AC;
$orange:#FA9917;
$red: #CE5849;

/* FONTS */
$primary-base-font: Poppins;
$secondary-base-font: Kanit;

$primary-font: $primary-base-font Arial sans-serif;
$secondary-font: $secondary-base-font Arial sans-serif;