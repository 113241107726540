@import '../assets/styles/variables.scss';

.mainContainer{
    background-color: white;
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    justify-content: flex-end;
}

.verticalContainer{
    padding-left:10px;
    width: 90vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.horizontalContainer{
    padding-left:2vw;
    padding-bottom:10vh;
    width: 60vw;
}

.firstLine{
    max-width: 60vw;
    font-size: 4.5vw;
    align-self: center;
    font-family:$primary-font;
    font-weight: 700;
}

.secondLine{
    max-width: 60vw;
    font-size: 5.2vw;
    text-decoration: underline;
    align-self: center;
    font-family:$primary-font;
    font-weight: 700;
}

.firstLinePortrait{
    max-width: 80vw;
    font-size: 6vw;
    align-self: flex-start;
    font-family:$primary-font;
    font-weight: 700;
}

.secondLinePortrait{
    max-width: 80vw;
    font-size: 6.8vw;
    text-decoration: underline;
    align-self: flex-start;
    font-family:$primary-font;
    font-weight: 700;
}