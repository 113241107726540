@import 'variables';


@font-face {
  font-family: $primary-font;
  font-weight: 200;
  font-style: normal;
  src:
    url("../fonts/manrope/Manrope-Light.ttf");
}

@font-face {
  font-family: $primary-font;
  font-weight: bold;
  font-style: normal;
  src:
    url("../fonts/manrope/Manrope-Bold.ttf");
}

@font-face {
  font-family: $primary-font;
  font-weight: 800;
  font-style: normal;
  src:
    url("../fonts/manrope/Manrope-ExtraBold.ttf");
}

@font-face {
  font-family: $primary-font;
  font-weight: normal;
  font-style: normal;
  src:
    url("../fonts/manrope/Manrope-Regular.ttf");
}

@font-face {
  font-family: $primary-font;
  font-weight: normal;
  font-style: 500;
  src:
    url("../fonts/manrope/Manrope-Medium.ttf");
}

@font-face{
    font-family: $secondary-font;
    font-weight: 800;
    src: url("../fonts/kanit/Kanit-SemiBold.ttf");
}