@import '../assets/styles/variables.scss';

.column{
    padding-top:10vh;
    display:flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    justify-content: space-evenly;
    align-items: center;
}

.row{
    padding-top:10vh;
    display:flex;
    flex-direction: row;
    width:100vw;
    height:100vh;
    justify-content: space-evenly;
    align-items:center;
}

.halfHorizontal{
    height: 75vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.contentVertical{
    width: 90vw;
}

.mapVertical{
    height: 45vh;
    width: 90vw;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.mapHorizontal{
    height: 80vh;
    width: 40vw;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.titlePortrait{
    font-size: 8vw;
    font-weight: 700;
    font-family: $primary-font;
}

.title{
    font-size: 4.3vw;
    font-weight: 700;
    font-family: $primary-font;
}

.descriptionPortrait{
    max-width: 90vw;
    font-size: 3.5vw;
    font-weight: 300;
    font-family: $primary-font;
    align-self: center;
}

.description{
    max-width: 90vw;
    font-size: 1.4vw;
    font-weight: 200;
    font-family: $primary-font;
    align-self: center;
    padding-bottom:30px;
}

.map{
    height: inherit;
}