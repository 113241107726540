@import '../assets/styles/variables.scss';

.column{
    padding-top:10vh;
    display:flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    justify-content: center;
}

.row{
    padding-top:8vh;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    justify-content: space-evenly;
    align-items:center;
}

.halfRow{
    height:50vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.halfColumn{
    height:45vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.container{
    width: 45vw;
    height:45vh;
    padding-left:15px;
}

.portraitContainer{
    height: 20vh;
    width: 93vw;
    padding-left:15px;
    padding-right:3vw;
}

.portraitContainerThree{
    height: 15vh;
    width: 93vw;
    padding-left:15px;
    padding-right:3vw;
}

.titleRowPortrait{
    width: 100%;
    height:40%;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;
}

.descriptionRowPortrait{
    width: 100%;
    height:60%;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.elementRowLandscape{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content:flex-start;
}

.number{
    font-size: 6vh;
    font-weight: 100;
    font-family: $primary-font;
}

.titlePortrait{
    padding-left:20px;
    font-size: 3vh;
    font-weight: 500;
    font-family: $primary-font;
    align-self: center;
}

.titleLandscape{
    padding-left:20px;
    font-size: 2.8vw;
    font-weight: 500;
    font-family: $primary-font;
    align-self: center;
    justify-content: center;
}

.description{
    max-width: 90vw;
    font-size: 3vw;
    font-weight: 300;
    font-family: $primary-font;
    align-self: center;
}

.descriptionLandscape{
    max-width: 90vw;
    font-size: 1.3vw;
    font-weight: 200;
    font-family: $primary-font;
    align-self: center;
}

.numberBlue{
    @extend .number;
    color: $blue;
}

.numberGreen{
    @extend .number;
    color: $green;
}

.numberRed{
    @extend .number;
    color: $red;
}

.numberYellow{
    @extend .number;
    color: $yellow;
}

.numberLandscape{
    font-size: 7vw;
    font-weight: 100;
    font-family: $primary-font;
}

.numberLandscapeBlue{
    @extend .numberLandscape;
    color: $blue;
}

.numberLandscapeGreen{
    @extend .numberLandscape;
    color: $green;
}

.numberLandscapeRed{
    @extend .numberLandscape;
    color: $red;
}

.numberLandscapeYellow{
    @extend .numberLandscape;
    color: $yellow;
}

.numberColumn{
    width: 10%;
}

.contentColumn{
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}