@import '../assets/styles/variables.scss';

.mainContainer{
    background-color: white;
    height:100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    justify-content: flex-end;
}

.column{
    padding-top:8vh;
    display:flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    justify-content: space-evenly;
}

.row{
    padding-top:8vh;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    justify-content: space-evenly;
    align-items:center;
}

.thirdColumn{
    display:flex;
    flex-direction: column;
    width: 33.3vw;
    height: 100vh;
    justify-content: center;
}

.thirdRow{
    display:flex;
    flex-direction: row;
    width: 100vw;
    height: 25vh;
    justify-content: center;
}

.elementContainer{
    margin-left:3vw;
    padding-left:10px;
    height:60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.elementContainerPortrait{
    margin-left:3vw;
    padding-left:10px;
    height:25vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.elementContainerMobile{
    @extend .elementContainer;
    border-left:8px solid $yellow;
}

.elementContainerPortraitMobile{
    @extend .elementContainerPortrait;
    border-left:8px solid $yellow;
}

.elementContainerPortraitWeb{
    @extend .elementContainerPortrait;
    border-left:8px solid $red;
}

.elementContainerPortraitUxui{
    @extend .elementContainerPortrait;
    border-left:8px solid $orange;
}

.elementContainerWeb{
    @extend .elementContainer;
    border-left:8px solid $red;
}

.elementContainerUxui{
    @extend .elementContainer;
    border-left:8px solid $orange;
}

.name{
    font-size: 3vw;
    align-self: flex-start;
    font-family:$primary-font;
    font-weight: 700;
}

.namePortrait{
    font-size: 5vw;
    align-self: flex-start;
    font-family:$primary-font;
    font-weight: 700;
}

.description{
    font-size: 1.5vw;
    align-self: flex-start;
    max-width: 25vw;
    font-family:$primary-font;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.descriptionPortrait{
    font-size: 3vw;
    align-self: flex-start;
    max-width: 80vw;
    font-family:$primary-font;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.descriptionUi{
    @extend .description;
    font-size: 1.37vw;
}

.descriptionUiPortrait{
    @extend .descriptionPortrait;
    font-size: 2.7vw;
}

.secondLine{
    max-width: 60vw;
    font-size: 5.2vw;
    text-decoration: underline;
    align-self: center;
    font-family:$primary-font;
    font-weight: 700;
}