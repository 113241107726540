@import '../assets/styles/variables.scss';

.column{
    background-color: white;
    height:88vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 12vh;
    justify-content: space-between;
}

.contentRow{
    height:73vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom:2vh;
}

.technologyRow{
    height:10vh;
    width: 80vw;
    padding-left:10vw;
    padding-right:10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.technologyIcon{
    height:inherit;
    width: auto;
    filter: grayscale(100%);
}

.listColumn{
    display: flex;
    height: 73vh;
    width: 20vw;
    flex-direction: column;
    justify-content: space-between;
}


.listElement{
    cursor: pointer;
    height:30%;
    background-color: $green;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    border: 3px solid black;
}

.listElementBerrinche{
    @extend .listElement;
    background-image: url('../assets/img/berrinche.png');
}

.listElementAbsolut{
    @extend .listElement;
    background-image: url('../assets/img/absolut.png');
}

.listElementDealxu{
    @extend .listElement;
    background-image: url('../assets/img/dealxu.png');
}

.projectColumn{
    height:73vh;
    width: 68vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projectIpadColumn{
    height:73vh;
    width: 68vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projectData{
    width: 66%;
    margin:0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    padding-right:20px;
}

.projectIpadData{
    width: 100%;
    margin:0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    padding-right:20px;
}

.projectImg{
    width: 35%;
    margin:0px;
    height: 100%;
}

.projectIpadImg{
    width: auto;
    height: inherit;
    
}


.projectIpadImgVertical{
    width: inherit;
    height: inherit;
    
}


.phoneScreenImg{
height: inherit;
}

.phoneScreenIpadImg{
    height: inherit;
}




.title{
    max-width: 95%;
    font-size: 4.5vw;
    font-weight: 500;
    font-family: $primary-font;
    text-align: end;
    justify-content: center;
}

.description{
    max-width: 95%;
    font-size: 1.3vw;
    font-weight: 200;
    font-family: $primary-font;
    text-align: end;
}

.button{
    height:40px;
    width: 40px;
    background-color: pink;
}

.sliderRow{
    overflow-x: scroll;
    flex-wrap: nowrap;
    height:78vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.techPortraitRow{
    height:5vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.technologyPortraitIcon{
    height:5vh;
    width: auto;
    filter: grayscale(100%);
}

.techPortraitContainer{
    height:5vh;
    width: 5vh;
    align-items: center;
}

.projectVerticalColumn{
    background-color:rgb(252, 252, 252);
    border-radius: 10px;
  //  border:0.1px solid #ccc;
  -webkit-box-shadow: 0px 0px 43px -18px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 43px -18px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 43px -18px rgba(0,0,0,0.2);
    height:75vh;
    width: 80vw;
    margin:10px;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projectVerticalImg{
    height:38vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.phoneScreenVerticalImg{
    height: inherit;
}
    
.projectVerticalData{
    width: 80vw;
    margin:0px;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
}

.titlePortrait{
    font-size: 5vw;
    font-weight: 500;
    font-family: $primary-font;
    align-self: flex-start;
}


.descriptionPortrait{
    max-width: 90vw;
    font-size: 3vw;
    font-weight: 300;
    font-family: $primary-font;
}

.video{
    height:40vh;
    margin:auto;
}

.videoVertical{
    width:70vw;
    height:auto;
    margin:auto;
}

.itemsCarousel{
    background-color: blue;
}